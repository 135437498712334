/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

//DO NOT TOUCH './override' LINE - IT MUST BE FIRST
import './override';

// import './debugger';
import {ModuleFE_ConnectivityModule, ModuleFE_Utils, ModuleFE_XHR, ModulePackFE_Thunderstorm, Storage_AppVersion, Thunder} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Account, ModulePackFE_Accounts} from '@nu-art/user-account/frontend';

import {defaultWorkspaceConfigs} from '@modules/WorkspaceModuleFE/workspaceConfigs';
import {ModuleFE_Workspace} from '@nu-art/ts-workspace/frontend';
import {ModulePackFE_DiseaseProfile} from '@app/dp/frontend';
import {Page_Main} from './app/pages/Page_Main/Page_Main';
import './app/App.scss';

import {KMApplicationName} from '@app/hcs';
import {App} from './app/App';
import {Module} from '@nu-art/ts-common';
import {ModuleFE_AppModule} from '@modules/app-module/ModuleFE_AppModule';
import {ModulePackFE_Permissions} from '@nu-art/permissions/frontend';
import {ModuleFE_FirebaseListener} from '@nu-art/firebase/frontend/ModuleFE_FirebaseListener/ModuleFE_FirebaseListener';
import {ModulePackFE_PushPubSub} from '@nu-art/push-pub-sub/frontend';
import {ModulePackFE_HCS_SeparatePermissionDomainModules, ModulePackFE_HealthcareSpace} from '@app/hcs/frontend';
import {ModulePackFE_Organization} from '@app/org/frontend';
import {ModulePackFE_Messaging} from '@nu-art/ts-messaging/frontend/module-pack';
import {ModulePackFE_Pathway} from '@app/pathway/frontend';
import {ModulePackFE_FocusedObject} from '@nu-art/ts-focused-object/frontend/modules/module-pack';
import {ModuleFE_Theme} from '@app/styles';
import {ModuleFE_DataInputLog, ModuleFE_FHIR, ModulePackFE_FHIR} from '@app/fhir/frontend';
import {ModulePackFE_LogTemplateDB} from '@app/advisor-content/_entity/log-template/frontend';
import {ModulePackFE_ShortUrlDB} from '@nu-art/ts-short-url/_entity/short-url/frontend';
import {HeaderKey_Application} from '@nu-art/thunderstorm';
import {ModulePackFE_AdvisorSnippetDB, ModulePackFE_AdvisorWizardDB, ModulePackFE_NLPModelDB, ModulePackFE_SmartDocDB} from '@app/advisor-content/frontend';
import {ModuleFE_PermissionManagerUI} from '@modules/ModuleFE_PermissionManagerUI/ModuleFE_PermissionManagerUI';
import {ModulePackFE_OrganizationPermissions} from '@app/org-permissions/frontend/module-pack';
import {addOrgPermissionsFE} from '@app/org-permissions/frontend/organization-permission-module-fe';
import {ConflictResolver_Overlay} from '@km/DependenciesConflict/ConflictResolver_Overlay/ConflictResolver_Overlay';

const modules: Module[] = [
	//TS
	ModuleFE_Theme,
	ModuleFE_Workspace,

	//FHIR
	ModuleFE_FHIR,
	ModuleFE_DataInputLog,

	//App Modules
	ModuleFE_AppModule,

	//FE UI Permission module
	ModuleFE_PermissionManagerUI,

	//generic renderer module
	ModuleFE_Utils
];
ModuleFE_Workspace.setAccountResolver(() => ModuleFE_Account.accountId);
ModuleFE_Workspace.setDefaultConfig({defaultConfigs: defaultWorkspaceConfigs});
// BaseComponent.MinLogLevel = LogLevel.Debug;
ModuleFE_XHR.addDefaultHeader(HeaderKey_Application, () => KMApplicationName);

// const prevAppVersion = Storage_AppVersion.get();
const appVersion = require('./version-app.json').version as string;

new Thunder()
	.addModulePack([ModuleFE_FirebaseListener])
	.addModulePack(ModulePackFE_Thunderstorm)
	.addModulePack(ModulePackFE_ShortUrlDB)
	.addModulePack([ModuleFE_ConnectivityModule])
	.addModulePack(ModulePackFE_Accounts)
	.addModulePack(ModulePackFE_Permissions)
	.addModulePack(ModulePackFE_PushPubSub)
	.addModulePack(ModulePackFE_HealthcareSpace)
	.addModulePack(ModulePackFE_HCS_SeparatePermissionDomainModules.map(module => addOrgPermissionsFE(module)))
	.addModulePack(ModulePackFE_DiseaseProfile)
	.addModulePack(ModulePackFE_Organization)
	.addModulePack(ModulePackFE_Pathway.map(module => addOrgPermissionsFE(module)))
	.addModulePack(ModulePackFE_Messaging)
	.addModulePack(ModulePackFE_FocusedObject)
	.addModulePack(ModulePackFE_LogTemplateDB)
	.addModulePack(ModulePackFE_OrganizationPermissions)
	.addModulePack(ModulePackFE_FHIR)
	.addModulePack(ModulePackFE_AdvisorWizardDB)
	.addModulePack(ModulePackFE_AdvisorSnippetDB)
	.addModulePack(ModulePackFE_SmartDocDB)
	.addModulePack(ModulePackFE_NLPModelDB)
	.addModulePack(modules)
	.setConfig(require('./config').config)
	.setVersion(appVersion)
	.setMainApp(App, {rootRoute: Page_Main.Route, additionalOverlays: [ConflictResolver_Overlay]})
	.addPreBuildAction(async () => {
		Storage_AppVersion.set(appVersion);
		// if (compareVersions(appVersion, prevAppVersion ?? '0.0.0') !== -1)
		// 	return;
		//
		// return await ModuleFE_StorageCleaner.cleanAll(() => {
		// 	Storage_AppVersion.set(appVersion);
		// });
	})
	.build(/*async () => {
		const serverInfo = await ModuleFE_ServerInfo.v1.getServerInfo({}).executeSync();
		if (!serverInfo.version)
			return;

		StaticLogger.logInfo(`App Version ${appVersion}, Server Version ${serverInfo.version}`);
		if (compareVersions(serverInfo.version, appVersion) === -1)
			NewAppVersionToast.show();
	}*/);